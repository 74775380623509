<template>
    <div class="role">
        <!-- <div class="tab-bar">
            <div
                @mousemove="tab(index)"
                v-for="(item,index) in items"
                :key="index"
                :class="{active : index==curId}"
            >
            &bull;&nbsp;{{item.item}}
            </div>
        </div> -->
        <div class="mb-role" v-if="fullWidth < 900">
            <div class="s-main">
                <swiper class="swiper-box" :options="swiperOption" ref="mySwiper" >
                    <!-- slides -->
                    <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/reservation_p04_active(1).jpg" alt=""></swiper-slide>
                    <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/reservation_p04_active(2).jpg" alt=""></swiper-slide>
                    <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/reservation_p04_active(3).jpg" alt=""></swiper-slide>
                    <!-- Optional controls -->
                    <div class="swiper-scrollbar"   slot="scrollbar"></div>
                </swiper>
                <div class="swiper-pagination1"  slot="pagination1"></div>
                <div class="swiper-button-prev" @click="prev" slot="button-prev"></div>
                <div class="swiper-button-next" @click="next" slot="button-next"></div>
            </div>
        </div>
        <div v-else class="tab-cont">
            <ul>
                <li @mousemove="change(index)" v-for="(item,index) in imgs" :key="index">
                    <img :src="item.bg" alt="">
                    <div style="width:100%;height:100%">
                        <img :class="curId == index ?'anim_fade_image':''" :src="index == curId ? item.bg1 : ''" alt="">
                    </div>
                </li>
            </ul>
        </div>
        <!-- <div class="scroll" @click="scrollPage()"></div> -->
    </div>
</template>

<script>
export default {
    data(){
        return{
            isFixed: false,
            offsetTop:0,
            curId: 1,
            fullWidth: 0,
            items: [
                {item: ''},
                {item: ''},
                {item: ''},
            ],
            imgs:[
                {
                    bg:require("../../assets/images/reservation_p04_BG02(1).png"),
                    bg1:require("../../assets/images/reservation_p04_active(1).jpg"),
                },
                {
                    bg:require("../../assets/images/reservation_p04_BG02(2).png"),
                    bg1:require("../../assets/images/reservation_p04_active(2).jpg"),
                },
                {
                    bg:require("../../assets/images/reservation_p04_BG02(3).png"),
                    bg1:require("../../assets/images/reservation_p04_active(3).jpg"),
                }
            ],
            swiperOption: { 
                //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
                notNextTick: true, 
                // pagination: '.swiper-pagination1', 
                slidesPerView: 'auto', 
                centeredSlides: true, 
                paginationClickable: true, 
                autoplay: 3000,
                spaceBetween: 30, 
                loop:true
            },
        }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    methods:{
        change(index) {
            let _this = this
            _this.curId = index
        },
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
        prev(){
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        next(){
            this.$refs.mySwiper.$swiper.slideNext();
        },
    },
    mounted() {
        this.handleResize() 
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style>
ul li{
    position: relative;
}
ul li div{
    position: absolute;
    top: 0;
}
.role{
    width: 100%;
    margin-top:-2px;
    position: relative;
    /* position: relative;width: 100%;
    position: relative;
    background: url('../../assets/images/reservation_02.png') no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc( 1080 / 1920 * 100vw );
    overflow: hidden;
    min-height: 1200px; */
}
.mb-role{
    width: 100%;
	font-size:0;
    position: relative;
    position: relative;width: 100%;
	font-size:0;
    position: relative;
    background: url('../../assets/images/reservation_03.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc( 1080 / 1920 * 100vw );
    overflow: hidden;
    min-height: 1200px;
}
.mb-role .s-main{
    position: absolute;
    text-align: center;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
}

.swiper-pagination1{
    bottom: -30%;
    width: 100%;
}
.tab-bar{
    position: absolute;
    text-align: center;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 100%;
    height: 100%;
}
.tab-bar div{
    display: block;
    flex-grow: 1;
    cursor: pointer;
}
.tab-cont{
    position: relative;
    text-align: center;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    font-size: 0;
}

.tab-cont li{
    margin: 0 -1px;
    flex-grow: 1;
}
.tab-cont img{
    background-size: 100%;
    width: 100%;
}
/* @media only screen and (min-width: 900px){ */
    .tab-cont ul{
        display: flex;
        cursor: pointer;
    }
    @-webkit-keyframes fadeInOut {
        0% {
            opacity: 0;
            transform: translateY(-100%);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
    .anim_fade_image { 
        -webkit-animation-name: fadeInOut;
        -webkit-animation-timing-function: ease-in-out;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-duration: 0.5s;
        -webkit-animation-direction: alternate;
        -moz-animation-name: fadeInOut;
        -moz-animation-timing-function: ease-in-out;
        -moz-animation-iteration-count: 1;
        -moz-animation-duration: 0.5s;
        -moz-animation-direction: alternate;
        -o-animation-name: fadeInOut;
        -o-animation-timing-function: ease-in-out;
        -o-animation-iteration-count: 1;
        -o-animation-duration:0.5s;
        -o-animation-direction: alternate;
        /* opacity: 0;
        transform: translateX(50%);
        transition: all 0.3s ease 0s; */

    }
/* } */
@media only screen and (max-width: 900px){
    .mb-role{
        height: 100%;
        min-height: 1400px;
    }
} 
</style>