<template>
    <div class="footer">
        <div class="cont-img">
            <img v-if="fullWidth > 767" src="../../assets/images/05p_title.png" alt="">
            <img v-else src="../../assets/images/05p_title（1）.png" alt="">
            <img v-if="fullWidth > 767" src="../../assets/images/05p_contents.png" alt="">
            <img v-else src="../../assets/images/05p_contents_m.png" alt="">
            <a href="https://cafe.naver.com/classic3k" target="_blank">
                <img src="../../assets/images/05p_btn.png" alt="">
            </a>
        </div>
        <div class="scroll" @click="scrollPage()"></div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            fullWidth: 0,
        }
    },
    mounted() {
        this.handleResize() 
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    methods:{
        scrollPage(){
            document.querySelector(".role").scrollIntoView(true);
        },
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
    
}
</script>

<style>
.footer{
    width: 100%;
	font-size:0;
    position: relative;
    background: url('../../assets/images/reservation_p03_BG.jpg') no-repeat;
    background-size:100% 100%;
    background-position: center center;
    height: calc( 1080 / 1920 * 100vw );
    overflow: hidden;
    min-height: 1900px;
    margin-top:-5px;
}
.footer .scroll{
    background: url('../../assets/images/xl.png');
    background-size: 80%;
    position: absolute;
    bottom: 8%;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    width: 8vw;
    height: 8vw;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    animation: scrolldown 1s ease infinite;
}
.footer .cont-img{
    position: absolute;
    text-align: center;
    top: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
}
.footer .cont-img a{
    display: block;
}
.footer .cont-img img{
    /* width: 100%; */
    height: 100%;
    margin-bottom: 80px;

}
.footer .cont-img img:nth-child(3){
    cursor: pointer;
    /* width: 60%; */
}
@media only screen and (max-width: 900px){
    .footer{
        height: 100%;
        min-height: 1550px;
    }
    .footer .cont-img{
        width: 70%;
    }
    .footer .cont-img img{
        margin-bottom: 20px;
    }
    .footer .cont-img img:nth-child(3){
        width: 100%;
    }
    .footer .cont-img img{
        width: 100%;
        height: 100%;
        margin-bottom: 80px;

    }
}
@media all and (min-width: 900px) and (max-width: 1025px){
    .footer .cont-img{
        width: 70%;
    }
}
@media all and (min-width: 900px) and (max-width: 1500px){
    .footer{
        min-height: 1600px;
    }
}
</style>