<template>
    <div class="swiper">
        <div class="s-main">
            <swiper class="swiper-box" :options="swiperOption" ref="mySwiper" >
                <!-- slides -->
                <swiper-slide style="position: relative;">
                    <img style="width:100%;height:100%" src="../../assets/images/s1.jpg" alt="">
                    <img @click="masksCloseFun()" class="bf" src="../../assets/images/icon04.png" alt="">
                </swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s2.jpg" alt=""></swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s3.jpg" alt=""></swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s4.jpg" alt=""></swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s8.jpg" alt=""></swiper-slide>
                <!-- <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s7.jpg" alt=""></swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s5.jpg" alt=""></swiper-slide>
                <swiper-slide><img style="width:100%;height:100%" src="../../assets/images/s6.jpg" alt=""></swiper-slide> -->
                <!-- Optional controls -->
                <div class="swiper-scrollbar"   slot="scrollbar"></div>
            </swiper>
            <div class="swiper-pagination"  slot="pagination"></div>
            <div class="swiper-button-prev" @click="prev" slot="button-prev"></div>
            <div class="swiper-button-next" @click="next" slot="button-next"></div>
        </div>
        <div class="tc" v-if="!videoState" @click="open">
            <div class="videomasks">
                <!-- <img @click="open" class="xx" src="../../assets/images/c.png" alt=""> -->
                <iframe width="1500" height="800" src="https://www.youtube.com/embed/sK_ig5qi4jg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        <!-- <div class="scroll" @click="scrollPage()"></div> -->
    </div>
</template>

<script>
  export default {
    name: 'carrousel',
    data() {
      return {
        videoState:true,
        swiperOption: { 
            //是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true 
            notNextTick: true, 
            pagination: '.swiper-pagination', 
            slidesPerView: 'auto', 
            centeredSlides: true, 
            paginationClickable: true, 
            autoplay: 3000,
            spaceBetween: 30, 
            loop:true
        },
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted() {
    },
    methods:{
        scrollPage(){
            document.querySelector(".role").scrollIntoView(true);
        },
        prev(){
            this.$refs.mySwiper.$swiper.slidePrev();
        },
        next(){
            this.$refs.mySwiper.$swiper.slideNext();
        },
        masksCloseFun(){
            this.videoState = !this.videoState
        },
        open(){
            this.videoState = !this.videoState
        }
    }
  }
</script>

<style>
.swiper{
    width: 100%;
    margin-top:-2px;
    position: relative;
    background: url('../../assets/images/reservation_p05_BG.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc( 1080 / 1920 * 100vw );
    overflow: hidden;
    min-height: 1200px;
}
.swiper .scroll{
    background: url('../../assets/images/xl.png');
    background-size: 80%;
    position: absolute;
    bottom: 10%;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    width: 8vw;
    height: 8vw;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    animation: scrolldown 1s ease infinite;
}
@keyframes scrolldown{0%{transform:translateX(-50%) translateY(0px)}50%{transform:translateX(-50%) translateY(30px)}100%{transform:translateX(-50%) translateY(0px)}}
.bf{
    position: absolute;
    text-align: center;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    width: 100px;
}
.tc{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.3);
    z-index: 1000000;
}
.videomasks{
  /* max-width: 1200px; */
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%,-50%);
}
.videomasks iframe{
  /* width:800px;height:500px */
}
.videomasks .xx{
    position: absolute;
    right: -50px;
    cursor: pointer;
}
.swiper .s-main{
    position: absolute;
    text-align: center;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
}
.swiper .s-main .swiper-box{
    position: relative;
}
.swiper-button-prev{
    background: url('../../assets/images/reservation_08.png') no-repeat;
    background-size: 100% 100%;
    height:80px;
    width: 40px;
    position: absolute;
    left: -15%;
}
.swiper-button-next{
    background: url('../../assets/images/reservation_07.png') no-repeat;
    background-size: 100% 100%;
    width: 40px;
    height:80px;
    position: absolute;
    right: -15%;
}
.swiper-pagination{
    position: absolute;
    bottom: -15%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
}
.swiper-pagination-bullet-active{
    opacity: 1;
    background: red !important;
    width: 20px;
    height: 20px;
    -webkit-box-shadow: #fff 0px 0px 8px;
    -moz-box-shadow: #fff 0px 0px 8px;
    box-shadow: #fff 0px 0px 8px;
}
.swiper-pagination-bullet{
    width: 20px;
    height: 20px;
    margin: 0 20px;
    background: #fff;
    opacity: 0.9;
    -webkit-box-shadow: #fff 0px 0px 8px;
    -moz-box-shadow: #fff 0px 0px 8px;
    box-shadow: #fff 0px 0px 8px;
}
@media only screen and (max-width: 900px){
    .swiper{
        height: 100%;
        min-height: 800px;
    }
    .swiper-pagination{
        bottom: -30%;
        width: 100%;
    }
    .swiper-pagination-bullet-active{
        width: 15px;
        height: 15px;
    }
    .swiper-pagination-bullet{
        width: 15px;
        height: 15px;
    }
    .swiper .s-main{
        width: 70%;
    }
    .videomasks iframe{
        width: 100%;
        height: 100%;
    }
}
</style>