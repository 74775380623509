<template>
    <div class="header">
        <video v-show="fullWidth > 900" autoplay muted loop src="https://cdn-pangsky.panggame.com/luandou/home.mp4"></video>
        <div class="scroll" @click="scrollPage()"></div>
        <div class="btns">
            <p><a target="_blank" href="https://play.google.com/store/apps/details?id=com.joymeka.ctk.google" @click="tagging('Lead')"><img class="img1" src="../../assets/images/google-play-badge.png" alt=""></a></p>
            <p><a target="_blank" href="https://m.onestore.co.kr/mobilepoc/apps/appsDetail.omp?prodId=0000754818" @click="tagging('Contact')"><img class="img2" src="../../assets/images/one_Pre-register_kr_b.png" alt=""></a></p>
        </div>
        <div class="banner-text">
            <img src="../../assets/images/banner_txt.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name:'',
    data(){
        return{
            fullWidth: 0,
        }
    },
    methods:{
        tagging (event) {
            window.fbq("track", event, {
              value: 1.0,
              currency: "USD",
            });
            window.gtag("event", event);
        },
        scrollPage(){
            document.querySelector(".footer").scrollIntoView(true);
        },
        handleResize () {
            this.fullWidth = document.documentElement.clientWidth;
        },
    },
    mounted() {
        this.handleResize() 
    },
    created(){
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style>
.header{
    width: 100%;
	font-size:0;
    position: relative;
    position: relative;width: 100%;
	font-size:0;
    position: relative;
    background: url('../../assets/images/reservation_01.png') no-repeat;
    background-size: cover;
    background-position: center center;
    height: calc( 1080 / 1920 * 100vw );
    overflow: hidden;
    min-height: 1200px;
}
.header img{
    width: 100%;
    height: 100%;
}
video{
    position: absolute;
    left: 0%;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.header .scroll{
    background: url('../../assets/images/xl.png');
    background-size: 80%;
    position: absolute;
    bottom: 5%;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    width: 8vw;
    height: 8vw;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    animation: scrolldown 1s ease infinite;
}
@keyframes scrolldown{0%{transform:translateX(-50%) translateY(0px)}50%{transform:translateX(-50%) translateY(30px)}100%{transform:translateX(-50%) translateY(0px)}}

.header .banner-text{
    position: absolute;
    bottom: 30%;
    left: 50%;
    z-index: 999;
    text-align: center;
    transform: translateX(-50%);
}

.header .banner-text img{
    width: 100%;
}

.header .btns{
    position: absolute;
    bottom: 16%;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
    display: flex;
    text-align: center;
}
.header .btns p img{
    width: 80%;
}
.header .btns p .img1{
    height: auto;
    animation:  icon1 1.2s linear infinite;
}
.header .btns p .img2{
    height: auto;
    animation:  icon2 1.2s linear infinite;
    animation-delay:0.5s;
    -webkit-animation-delay:0.5s;
}

@keyframes icon1{
	25% {-webkit-transform: translateY(12px);}
    50%, 100% {-webkit-transform: translateY(0);}
    75% {-webkit-transform: translateY(-12px);}
}

@keyframes icon2{
	25% {-webkit-transform: translateY(14px);}
    50%, 100% {-webkit-transform: translateY(0);}
    75% {-webkit-transform: translateY(-14px);}
}
@media only screen and (max-width: 900px){
    .header{
        height: 100%;
    }
    .header .scroll{
        bottom: 10%;
    }
    .header .btns{
        width: 80%;
    }
    .header .btns p img{
        width: 100%;
    }
    .header .banner-text{
        bottom: 26%;
    }
    .header .banner-text img{
        width: 600px;
    }
}
</style>