<template>
  <div class="app-container">
    <div class="home-logo">
      <img src="../assets/images/logo.png" alt="">
    </div>
    <div class="home-butt">
			<a target="_blank" href="https://classic3k.joymeka.com/#/home">
				<img class="downloadBtn" src="../assets/images/icon01.png">
			</a>
      <a @click="copyCode()">
        <img class="downloadBtn" src="../assets/images/icon02.png">
      </a>
			<a target="_blank" href="https://cafe.naver.com/classic3k">
				<img class="downloadBtn" src="../assets/images/icon03.png">
			</a>
      <a target="_blank" href="https://www.youtube.com/watch?v=sK_ig5qi4jg">
				<img class="downloadBtn" src="../assets/images/icon04.png">
			</a>
      <a target="_blank" href="https://www.facebook.com/%EA%B3%A0%EC%A0%84%EC%82%BC%EA%B5%AD%EC%A7%80-100236292161362">
				<img class="downloadBtn" src="../assets/images/icon05.png">
			</a>
		</div>
    <Header></Header>
    <!-- <Register></Register> -->
    <Footer></Footer>
    <Role></Role>
    <Swiper></Swiper>
  </div>
</template>

<script>
import Header from './Item/Header'
import Role from './Item/Role'
import Swiper from './Item/Swiper'
// import Register from './Item/Register'
import Footer from './Item/Footer'
export default {
  name: "Home",
  components: {
    Header,
    Role,
    Swiper,
    // Register,
    Footer
  },
  methods:{
    copyCode() {
				var input = document.createElement("input"); // 直接构建input
				input.value = 'https://classic3k.joymeka.com/#/home'; // 设置内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.$toast("복사성공", "success")
			}
  }
};
</script>

<style>
.app-container{
  /* max-width: 1920px; */
  position: relative;
}
.home-butt {
  position: fixed;
  right: 50px;
  top: 30px;
  z-index: 1000;
  display: flex;
}

.home-butt > a {
  margin-left: 10px;
  text-align: right;
}
.home-butt > a img{
  cursor: pointer;
  width: 50%;
}
.home-logo{
  position: absolute;
  left: 50px;
  top: 0;
  z-index: 1000;
}
@media only screen and (max-width: 900px){
  .home-butt{
    top: 0;
  }
  .home-logo{
    left: 0;
    display: none;
  }
  .home-butt > a{
    margin-left: 0;
  }
}
</style>